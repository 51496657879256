<template>
  <AppPageCard>
    <template #card-title> Inventory </template>
    <b-table
      class="table-head-custom table-vertical-center"
      :items="item"
      :fields="fields"
    >
    </b-table>
  </AppPageCard>
</template>

<script>
import AppPageCard from "@/modules/core/components/shared/card/AppPageCard";

export default {
  name: "CompanyInventoryPage",
  components: { AppPageCard },
  data() {
    return {
      item: [
        { name: "Laptop", cat: "IT", nadoc: "5", cw2: "500", cw3: "298" },
        { name: "PC's", cat: "IT", nadoc: "10", cw2: "500", cw3: "2131" },
        { name: "Tablet", cat: "IT", nadoc: "10", cw2: "500", cw3: "455" },
        { name: "Chair", cat: "Others", nadoc: "100", cw2: "500", cw3: "312" },
        { name: "Table", cat: "Others", nadoc: "500", cw2: "500", cw3: "211" },
      ],
      fields: [
        { key: "name", label: this.$t("EMPLOYEETABLE.DEPARTMENTNAME") },
        { key: "cat", label: "Category" },
        { key: "nadoc", label: "NADOC" },
        { key: "cw2", label: "CW2" },
        { key: "cw3", label: "CW3" },
      ],
    };
  },
};
</script>

<style scoped></style>
